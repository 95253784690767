import gsap from 'gsap';
import { TweenMax, Power1 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function company(scene){
  const companyBar = document.querySelectorAll('.company__bar__item')
  companyBar.forEach((el) => {
    scene.push(
      gsap.fromTo(
        el,
        {
          top: '0%',
        },
        {
          y: '-50%',
          top: '100%',
          duration: 1,
          ease: Power1.easeOut,
          scrollTrigger: {
            trigger: '.company__bar',
            start: 'top center',
            end: 'bottom center',
            scrub: true,
            //markers: true
          },
        }
      )
    )
  })

};

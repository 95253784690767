import * as imagesLoaded from 'imagesloaded';
import {index} from './index';

export function loading(scene){
  const loading = document.getElementById('loading')
  const img = document.querySelectorAll('img')

  if(loading){
    loading.classList.add('view')
    imagesLoaded(img, function(){
      showDispay(loading, scene);
    })
  }else{
    imagesLoaded(img, function(){
      index(scene)
    })
  }
};

function showDispay(el, scene){
  el.classList.add('active')
  setTimeout(()=>{
    el.classList.add('remove')
    index(scene)
  }, 3000)

  setTimeout(()=>{
    el.remove()
  }, 4000)
}
import Swiper from 'swiper/bundle';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function index(scene){

  const fv = document.getElementById('firstview')
  if(fv){
    fv.classList.add('active')
  }

  const indexSlides = document.getElementById('indexSlides')
  if(indexSlides){
    new Swiper(indexSlides, {
      slidesPerView: 'auto',
      loop: true,
      spaceBetween: 40,
      centeredSlides: true,
    })
  }
}




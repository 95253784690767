import barba from '@barba/core';
import gsap from 'gsap';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min'
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {scrolEvents} from './default';
import {loading} from './loading';
import {company} from './company';
import {concept} from './concept';
import {recruit} from './recruit';
import {vm} from './vm';
gsap.registerPlugin(ScrollTrigger);

let scene = []
let smoothScroll
const scoption = { speed: 400, easing: 'easeInOutQuart', speedAsDuration: true, durationMin: 400, header: '[data-scroll-header]', updateURL: true }
const body = document.body

document.addEventListener('DOMContentLoaded', function() {
  initialize(scene)
  vm()
  smoothScroll= new SmoothScroll();


  barba.init({
    transitions: [{
      name: 'opacity-transition',
      leave(data) {
        body.classList.add('disable')
        return gsap.to(data.current.container, {
          opacity: 0,
          duration: .8,
          onComplete: removeEffect,
          onCompleteParams: [data.current.container]
        });
      },
      enter(data) {
        return gsap.from(data.next.container, {
          opacity: 0,
          duration: .4,
          onComplete: initialize,
          onCompleteParams: [scene]
        });
      }
    }]
  });

  function initialize(scene){
    reflash(scene)
    scrolEvents(scene)
    loading(scene)
    company(scene)
    recruit(scene)
    concept(scene)
    firstpaint();
		contactForm7Run();
  }

  function removeEffect(el){
    el.remove()
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }

  function reflash(s){
    body.classList.remove('disable')
    for(let i = 0; i < s.length; i++){
      if(s[i].scrollTrigger != null){
        s[i].scrollTrigger.kill()
      }
      if(s[i].kill !== null){
        s[i].kill()
      }
    }
  }

  function firstpaint() {
    const firstpaint = document.querySelectorAll('.firstpaint')
    firstpaint.forEach((el)=>{
      el.classList.add('active')
    })
  }
	
	// Contact Form 7
	function contactForm7Run() {
		const cfSelector = document.querySelector('div.wpcf7 > form')
		if (cfSelector) {
			wpcf7.init(cfSelector);
		}
	}
	
});
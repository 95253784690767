import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';

export function scrolEvents(scene){
    if(document.getElementsByClassName('rellax').length > 0){
      const rellax = new Rellax('.rellax',{
          center:true
      });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
      const rellax_no = new Rellax('.rellax-no',{
          center:false
      });
    }

    if(document.getElementById('firstview')){
      document.body.classList.add('none')
    }else if(document.getElementById('pageBlackView')){
      document.body.classList.remove('none')
      document.body.classList.add('black')
    }else{
      document.body.classList.remove('none')
      document.body.classList.remove('black')
    }

    const fixscroll = document.querySelectorAll('.fixscroll')
    fixscroll.forEach((el) => {
      scene.push(
        gsap.fromTo(
          el,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            duration: 1,
            scrollTrigger: {
              trigger: document.body,
              start: 'top top',
              end: '100px top',
              scrub: true,
              //markers: true
            },
          }
        )
      )
    })


    /*
    const sections = document.querySelectorAll(".blurscroll")
    sections.forEach((el) => {
      scene.push(
        gsap.fromTo(
          el,
          { 
            opacity: 1,
            filter: 'blur(0)',
            transform: 'scale(1) rotateX(0)',
          },
          {
            opacity: 0,
            filter: 'blur(0)',
            transform: 'scale(.88) rotateX(20deg)',
            duration: 1,
            ease: Power2.easeInOut,
            scrollTrigger: {
              trigger: el,
              start: 'top top',
              end: 'bottom top',
              scrub: true,
              //markers: true
            },
          }
        )
      )
    })
    */

}